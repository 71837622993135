//
// vector-maps.scss
//

.jvectormap-label {
    border: none;
    background: $gray-900;
    color: $gray-200;
    font-family: $font-family-secondary;
    font-size: $font-size-base;
    padding: 5px 8px;
}