
/* -------------------------------------
 * Bar container
 * ------------------------------------- */
 .radial-bar {
  position: relative;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  border: 2px solid lighten($gray-300, 1%);
  background-color: transparent;
  margin-bottom: 20px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
}
.radial-bar:after,
.radial-bar > img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  color: $dark;
}
.radial-bar:after {
  content: attr(data-label);
  background-color: lighten($gray-300, 1%);
  z-index: 101;
}
.radial-bar > img {
  z-index: 102;
}
.radial-bar:after,
.radial-bar > img {
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}

.radial-bar.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-50 {
  background-image: linear-gradient(270deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-55 {
  background-image: linear-gradient(288deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-60 {
  background-image: linear-gradient(306deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-65 {
  background-image: linear-gradient(324deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-70 {
  background-image: linear-gradient(342deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-75 {
  background-image: linear-gradient(360deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-80 {
  background-image: linear-gradient(378deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-85 {
  background-image: linear-gradient(396deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-90 {
  background-image: linear-gradient(414deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-95 {
  background-image: linear-gradient(432deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar.radial-bar-100 {
  background-image: linear-gradient(450deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-50 {
  background-image: linear-gradient(270deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-55 {
  background-image: linear-gradient(288deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-60 {
  background-image: linear-gradient(306deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-65 {
  background-image: linear-gradient(324deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-70 {
  background-image: linear-gradient(342deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-75 {
  background-image: linear-gradient(360deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-80 {
  background-image: linear-gradient(378deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-85 {
  background-image: linear-gradient(396deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-90 {
  background-image: linear-gradient(414deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-95 {
  background-image: linear-gradient(432deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-primary.radial-bar-100 {
  background-image: linear-gradient(450deg, $primary 50%, transparent 50%, transparent), linear-gradient(270deg, $primary 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-50 {
  background-image: linear-gradient(270deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-55 {
  background-image: linear-gradient(288deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-60 {
  background-image: linear-gradient(306deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-65 {
  background-image: linear-gradient(324deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-70 {
  background-image: linear-gradient(342deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-75 {
  background-image: linear-gradient(360deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-80 {
  background-image: linear-gradient(378deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-85 {
  background-image: linear-gradient(396deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-90 {
  background-image: linear-gradient(414deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-95 {
  background-image: linear-gradient(432deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-success.radial-bar-100 {
  background-image: linear-gradient(450deg, $success 50%, transparent 50%, transparent), linear-gradient(270deg, $success 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-50 {
  background-image: linear-gradient(270deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-55 {
  background-image: linear-gradient(288deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-60 {
  background-image: linear-gradient(306deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-65 {
  background-image: linear-gradient(324deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-70 {
  background-image: linear-gradient(342deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-75 {
  background-image: linear-gradient(360deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-80 {
  background-image: linear-gradient(378deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-85 {
  background-image: linear-gradient(396deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-90 {
  background-image: linear-gradient(414deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-95 {
  background-image: linear-gradient(432deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-info.radial-bar-100 {
  background-image: linear-gradient(450deg, $info 50%, transparent 50%, transparent), linear-gradient(270deg, $info 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-50 {
  background-image: linear-gradient(270deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-55 {
  background-image: linear-gradient(288deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-60 {
  background-image: linear-gradient(306deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-65 {
  background-image: linear-gradient(324deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-70 {
  background-image: linear-gradient(342deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-75 {
  background-image: linear-gradient(360deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-80 {
  background-image: linear-gradient(378deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-85 {
  background-image: linear-gradient(396deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-90 {
  background-image: linear-gradient(414deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-95 {
  background-image: linear-gradient(432deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-warning.radial-bar-100 {
  background-image: linear-gradient(450deg, $warning 50%, transparent 50%, transparent), linear-gradient(270deg, $warning 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-50 {
  background-image: linear-gradient(270deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-55 {
  background-image: linear-gradient(288deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-60 {
  background-image: linear-gradient(306deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-65 {
  background-image: linear-gradient(324deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-70 {
  background-image: linear-gradient(342deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-75 {
  background-image: linear-gradient(360deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-80 {
  background-image: linear-gradient(378deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-85 {
  background-image: linear-gradient(396deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-90 {
  background-image: linear-gradient(414deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-95 {
  background-image: linear-gradient(432deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-danger.radial-bar-100 {
  background-image: linear-gradient(450deg, $danger 50%, transparent 50%, transparent), linear-gradient(270deg, $danger 50%, #fafafa 50%, #fafafa);
}
.radial-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px;
}
.radial-bar-lg:after,
.radial-bar-lg > img {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}
.radial-bar-sm {
  width: 50px;
  height: 50px;
  font-size: 12px;
}
.radial-bar-sm:after,
.radial-bar-sm > img {
  width: 35px;
  height: 35px;
  margin-left: 7.5px;
  margin-top: 7.5px;
  line-height: 35px;
}
.radial-bar-xs {
  width: 30px;
  height: 30px;
  font-size: 8px;
}
.radial-bar-xs:after,
.radial-bar-xs > img {
  width: 21px;
  height: 21px;
  margin-left: 4.5px;
  margin-top: 4.5px;
  line-height: 21px;
}

.radial-bar {
  background-clip: content-box;
}