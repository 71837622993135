// 
// widgets.scss
//



/* Inbox-widget */

.inbox-widget {
    .inbox-item {
      overflow: hidden;
      padding: 10px 0;
      position: relative;
      .inbox-item-img {
        display: block;
        float: left;
        margin-right: 15px;
        width: 40px;
        margin-top: 6px;
      }
      img {
        width: 40px;
      }
      .inbox-item-author {
        color: $dark;
        display: block;
        margin: 0;
      }
      .inbox-item-text {
        color: $text-muted;
        display: block;
        font-size: 14px;
        margin: 0;
      }
      .inbox-item-date {
        color: $text-muted;
        font-size: 11px;
        position: absolute;
        right: 7px;
        top: 12px;
      }
    }
  }
  

//   profile-widgets

  .profile-widget{
      img{
            margin-top: -40px;
            border: none;
          }
  }


  // widgets 1

  
  .widget-style-1{
    i {
      position: absolute;
      font-size: 3rem;
      right: -5px;
      bottom: 1rem;
      color: #edf0f0;
      transition: all 220ms ease-in-out;
    }
    &:hover{
     i{
      transform: scale(1.5);
     }
    }
  }

 // widgets 2

.widget-style-2 {
  i {
    font-size: 60px;
    padding: 25px 30px;
    margin-top: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
    background: rgba($white,0.2);
  }
}


/* Carousel */
.velonic-carousel {
    padding: 20px 30px;
  }
  
  .slider-bg {
    background: url("../images/gallery/11.jpg") center;
    background-size: cover;
    position: relative;
  }
  
  .slider-overlay {
    background: rgba(0,0,0,0.6);
    position: absolute;
    height: 100%;
    width: 100%;
  }
  
  .owl-buttons {
    display: none;
  }
  

/* Chat widget */

.conversation-list {
    list-style: none;
    padding: 0 20px;
    li {
        margin-bottom: 24px;
    }
    .chat-avatar {
        float: left;
        text-align: center;
        width: 42px;
        img {
            border-radius: 100%;
            width: 100%;
        }
        i {
            font-size: 12px;
            font-style: normal;
        }
    }
    .ctext-wrap {
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        background: lighten($primary, 36%);
        border-radius: 3px;
        display: inline-block;
        padding: 12px;
        position: relative;
        i {
            display: block;
            font-size: 12px;
            font-style: normal;
            font-weight: bold;
            position: relative;
        }
        p {
            margin: 0;
            padding-top: 3px;
        }
        &:after {
            right: 99%;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: lighten($primary, 36%);
            border-width: 8px;
            margin-left: -1px;
            border-right-color: lighten($primary, 36%);
        }
    }
    .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        width: 70%;
    }
    .odd {
        .chat-avatar {
            float: right !important;
        }
        .conversation-text {
            float: right !important;
            margin-right: 12px;
            text-align: right;
            width: 70% !important;
        }
        .ctext-wrap {
            background-color: $gray-300;
            &:after {
                border-color: transparent;
                border-left-color: $gray-300;
                border-top-color: $gray-300;
                left: 99% !important;
            }
        }
    }
}

