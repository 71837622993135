// 
// profile.scss
//
  
 
.profile-bg-picture {
  position: relative;
  min-height: 300px;
  margin: -19px -20px 0px -20px;
  background-position: center;
  background-size: cover;
}

.profile-user-box {
  background-color: $card-bg;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid $light;
  margin-top: -65px;
  position: relative;
  border-radius: 5px;
}

.profile-user-img img{
  position: absolute;
  top: -56px;
  border: 3px solid $white;
  box-shadow: 2px 6px 30px -15px $dark;
}

/* Timeline-2 */
.timeline-2 {
  position: relative;
  border-left: 2px solid $dark;
  .time-item {
    position: relative;
    padding-bottom: 1px;
    border-color: $light;
    :before, :after {
      display: table;
      content: " ";
    }
    :after {
      position: absolute;
      left: 0;
      top: 5px;
      width: 14px;
      height: 14px;
      margin-left: -8px;
      background-color: $white;
      border-color: $dark;
      border-style: solid;
      border-width: 2px;
      border-radius: 10px;
      content: '';
    }
  }
}








