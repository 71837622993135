.jqstooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  canvas {
      position: absolute;
      top: 0;
      left: 0;
  }
  .chart-widget-pie {
      margin-top: 5px;
      margin-bottom: 5px;
  }
  .percent {
      display: inline-block;
      line-height: 110px;
      z-index: 2;
      &:after {
          content: '%';
          margin-left: 0.1em;
          font-size: .8em;
      }
  }
}