
  // coomin-soon

  .counter-number {
    font-size: 48px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-bold;
    color: $primary;
    span {
        font-size: 16px;
        font-weight: $font-weight-normal;
        display: block;
        padding-top: 10px;
        color: $dark;
    }
  }

  .coming-box{
      float: left;
      width: 25%;
  }

//   404 page

.wrapper-page{
    h1 {
        font-size: 98px;
        font-weight: $font-weight-bold;
        line-height: 150px;
        text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
      }
}
  

// pricing

.pricing-box{
  border: 2px solid lighten($gray-400, 6%);
}