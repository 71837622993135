//
// summernote.scss
//

@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/summernote.eot");
  src: url("../fonts/summernote.eot?#iefix") format("embedded-opentype"), url("../fonts/summernote.woff?") format("woff"), url("../fonts/summernote.ttf?") format("truetype")
}

.note-editor{
  &.note-frame {
    border: 1px solid lighten($gray-300,4%);
    box-shadow: none;
    margin: 0;

    .note-statusbar {
      background-color: lighten($gray-300, 4%);
      border-top: 1px solid $gray-300;
    }

    .note-editing-area{
      .note-editable, .note-codable {
        border: none;
        color: $gray-600;
        background-color: transparent;
      }
    }
  }

  .note-btn-group{
    .note-btn{
      background-color: lighten($gray-300, 4%) !important;
      border-color: lighten($gray-300, 4%) !important;
    }
  }
}

.note-status-output {
  display: none;
}

.note-editable {
  border-radius: $input-border-radius;
  padding: $input-padding-y $input-padding-x;
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.note-popover .popover-content .note-color .dropdown-menu,
.card-header.note-toolbar .note-color .dropdown-menu {
    min-width: 344px;
}

.note-popover{
  border-color: $gray-300;
}

.note-popover .popover-content, 
.card-header.note-toolbar{
  background-color: lighten($gray-300, 4%);
}

.note-toolbar {
   z-index: 1;
}