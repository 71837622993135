
// 
// authentication.scss
//

.authentication-page{
    padding-bottom: 0px;
    &.enlarged {
        min-height: 100px;
    }

    h4{
        z-index: 999;
        position: relative;
        font-size: 24px;;
    }
}